@use '@angular/material' as mat;
@use 'variables' as variables;
@use 'material' as *;
@use 'reset' as *;
@use '@angular/cdk/overlay-prebuilt.css' as *;
@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.elevation-classes();
@include mat.app-background();

// 基本テーマ
$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: mat.m2-define-palette(mat.$m2-blue-palette, 700),
      accent: mat.m2-define-palette(mat.$m2-pink-palette, 500),
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// テーマの適用
@include mat.all-component-themes($my-theme);

$my-form-field-theme: mat.m2-define-light-theme(
  (
    density: -1,
  )
);
@include mat.form-field-theme($my-form-field-theme);

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100vh;
  font-size: 62.5%;
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', 'MS PGothic',
    sans-serif;
}

body {
  background-color: variables.$usonar-base-color;
  font-size: 1.4rem;
  color: variables.$gray12;
  height: 100%;
  line-height: 1.4;
}

.unstyled-button {
  display: inline-block;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  width: fit-content;
  height: fit-content;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}

.mat-simple-snackbar {
  white-space: pre-wrap;
}

.confirm-dialog .mat-dialog-container {
  padding: 0;
}

/* アイコンやテキストの色 */
.color-gray {
  color: variables.$gray;
}
.color-success {
  color: variables.$success;
}
.color-error {
  color: variables.$error;
}
.color-warn {
  color: variables.$warn;
}
.color-info {
  color: variables.$info;
}
.color-delete {
  color: variables.$delete;
}
.color-white {
  color: white;
}
.color-primary {
  color: variables.$primary9;
}
