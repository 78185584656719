/**
* ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
* ↓↓↓↓↓↓↓↓↓↓ Figma のカラーテーマ ↓↓↓↓↓↓↓↓
* ↓↓↓↓↓ （これ以外の`色`は全て削除予定します！） ↓↓↓↓
* ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
*
* 番号の選び方についてはこちらを参照する
* https://www.radix-ui.com/docs/colors/palette-composition/understanding-the-scale#use-cases
*
*/

$gray1: #ffffff; // App background
$gray2: #f8f9fa; // Subtle background
$gray3: #f2f3f4; // UI element background
$gray4: #eceef0; // Hovered UI element background
$gray5: #e6e8eb; // Active / Selected UI element background
$gray6: #e0e3e6; // Subtle borders and separators
$gray7: #d8dbdf; // UI element border and focus rings
$gray7A: #d8dbdf80;
$gray8: #c2c8cc; // Hovered UI element border
$gray9: #8a9095; // Solid backgrounds
$gray10: #80868c; // Hovered solid backgrounds
$gray11: #6a7075; // Low-contrast text
$gray12: #13181c; // High-contrast text

$primary1: #f0f7fe;
$primary2: #d7e8f9;
$primary3: #bdd9f3;
$primary4: #a3c9ee;
$primary5: #89bbe9;
$primary6: #71ace4;
$primary7: #589edf;
$primary7A: #589edf80;
$primary8: #408fdb;
$primary9: #1676d2; // 管理ソナーのメインカラー
$primary10: #1365b4;
$primary11: #115a9f;
$primary12: #00407c;

$success: #4be877;
$info: #4aa8ff;
$warn: #ffb74a;
$error: #ff5c5c;
$delete: #ff0e3c;

// チャートのみで使用する色
$chart-green: #b2ffc3;
$chart-red: #ffb9b9;
$chart-yellow: #ffeeb1;
$chart-sky: #67e0ff;
$chart-orange: #ffb38a;
$chart-purple: #9497ff;
$chart-pink: #ffa0ec;
$chart-lemon: #f5ff68;
$chart-teal: #508548;
$chart-violet: #5a559d;

/**
* ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
* ↑↑↑↑↑↑↑↑↑↑ Figma のカラーパレット ↑↑↑↑↑↑↑↑
* ↑↑↑↑↑ （これ以外の`色`は全て削除予定します！） ↑↑↑↑
* ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
*/

/* uSonar 領域設定
*-------------------------------------------------------------------------*/
// アプリケーションの最小サイズ
$app-min-size: (
  width: 1000px,
  height: 720px,
);

/* uSonar 色設定
*-------------------------------------------------------------------------*/

// uSonar ベースカラー (ページの背景色で使用される色)
$usonar-base-color: #f0f0f0;

// uSonar メインカラー (コンテンツの背景色で使用される色)
$usonar-main-color: #ffffff;

// uSonar アクセントカラー (ボタンなどのページ内で強調したい要素に使用される色)
// angular-materialのカラーパレット使用
$usonar-accent-color: #1976d2;

// 見出しのフォントカラー
$usonar-heading-font-color: #666666;

// サブ見出しのフォントカラー
$usonar-sub-heading-font-color: #777777;

// 警告文や注意書きのカラー
$usonar-alert-color: #e80042;

// 削除ボタンのカラー
$usonar-delete-color: $delete;

// primaryボタンのボタンのフォーカスリングカラー
$focus-ring-primary: #55adffb5;

// deleteボタンのボタンのフォーカスリングカラー
$focus-ring-delete: #ff6955b5;

// ボーダー色(一旦materialのデフォルト)
$border-color: rgba(0, 0, 0, 0.12);

// 明るい灰色
$light-gray: #f0f0f0;

// 灰色
$gray: #aaa;

// 暗い灰色
$dark-gray: #4a4a4a;

// コンテンツに適応するbox-shadow設定
$content-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

// 色を統一するために置き換え予定（@smiyahara）
$success-color: $success;
$error-color: $error;
$warn-color: $warn;
$info-color: $info;

/* uSonar 余白設定
*-------------------------------------------------------------------------*/
// ページの余白
$page-padding: 24px;

// コンテンツの間隔
$content-margin: 16px;

// コンテンツ内の要素の間隔
$element-margin: 8px;

/* uSonar 文字サイズ設定
*-------------------------------------------------------------------------*/
// 見出し 文字サイズ
$lg-heading: 1.8rem; /* -> 18px */
$md-heading: 1.4rem; /* -> 14px */
$sm-heading: 1.2rem; /* -> 12px */

// コンテンツ 文字サイズ
$lg-content: 1.6rem; /* -> 16px */
$md-content: 1.4rem; /* -> 14px */
$sm-content: 1.2rem; /* -> 12px */

// 数値 文字サイズ
$lg-number: 3.4rem; /* -> 34px */
$md-number: 2.6rem; /* -> 26px */
$sm-number: 2rem; /* -> 20px */

// ページ見出し 文字サイズ
$page-heading: 2rem; /* -> 20px */

// コンテンツ見出し 文字サイズ
$content-heading: 1.8rem; /* -> 18px */

/* グローバル部品 領域設定
*-------------------------------------------------------------------------*/
// グローバルヘッダ
$global-header: (
  width: 100%,
  height: 60px,
  background-image: linear-gradient(135deg, #1976d2 0%, #123772 100%),
);

// グローバルサイドナビ
$global-sidenav: (
  width: 100%,
  // グローバルサイドナビの背景色
  background-color: #ffffff,
);

// グローバルサイドナビボタン
$global-sidenav-button: (
  height: 50px,
  icon-width: 60px,
  icon-font-color: $usonar-accent-color,
  icon-font-size: 24px,
);

/* 認証 設定
*-------------------------------------------------------------------------*/
// ログイン画面
$login: (
  background-color: $usonar-base-color,
  card-width: 400px,
  card-height: 420px,
);

// パスワード再発行画面
$activation: (
  background-color: $usonar-base-color,
  card-width: 360px,
  card-height: 260px,
);

// パスワード設定画面
$reset: (
  background-color: $usonar-base-color,
  card-width: 400px,
  card-height: 420px,
);

/* お知らせ 設定
*-------------------------------------------------------------------------*/
$notifications: (
  // お知らせダイアログの幅
  width: 395px,

  // お知らせダイアログの高さ
  height: 395px,

  // お知らせダイアログの背景色
  background-color: #fff,

  // お知らせダイアログのボーダー色
  border-color: #979797,

  //未読マーク 文字色
  unread-color: #2196f3,

  //未読マーク 文字サイズ
  unread-font-size: 6px
);

/* ターゲット検索 設定
*-------------------------------------------------------------------------*/
$confirm-condition-margin: 20px 60px 20px 60px;

// ターゲット検索 コンテンツの最小幅
$content-min-width: 940px;

// ターゲット検索 共通ヘッダ
$target-search-header: (
  background-color: #fff,
  min-width: 860px,
  height: 60px,
);

// ターゲット検索 共通ヘッダ トップナビゲーション
$top-navigator: (
  active-color: #666666,
  deactive-color: #cccccc,
);

$clear-conditions: (
  color: #757575,
  cursor: pointer,
);

/* 階層選択 設定
*-------------------------------------------------------------------------*/
$hierarchy-conditions: (
  height: 210px,
);

/* クロス集計 設定
*-------------------------------------------------------------------------*/
$cross-aggregations: (
  axis-backgroung-color: #f8f8f8,
  axis-value-color: #0096fa,

  cell-hover-color: #dddddd,
  cell-active-color: #acc6de,
  cell-active-hover-color: #94aabe,
  cell-border-color: #c4c4c4,
  top-header-cell-background-color: #666666,
  header-cell-background-color: #e5e5e5,

  penetration-background-color: #bbdefb,
  penetration-border-color: #90caf9,

  cross-header-height: 34px,
  cross-header-margin-bottom: 24px,
  cross-table-margin-side: 8px,
);

// ダウンロード
//---------------------------------------------------------------------------//
$download: (
  invalid-color: #c4c4c4,
);

// 企業拡張項目、データ
//---------------------------------------------------------------------------//
$extended-flex-grow: 0;
$extended-flex-shrink: 0;
$extended-flex-basis: 45%;
$extended-margin-right: 24px;
