@use 'variables' as variables;
/* AngularMaterialのcss設定を上書きする
*-------------------------------------------------------------------------*/

/* Angular Material Button Style */
// TODO: 代替案を模索
.mdc-button {
  min-width: 120px !important;
}

/** Angular FormFiled */
// FormField下の表示領域からメッセージがはみ出した時の表示設定
// TODO: 代替案を模索
.mat-mdc-form-field-subscript-wrapper {
  overflow: visible !important;
}

// FormField全体のflexでのレイアウト調整を行う
.custom-form-field .mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}

.small-form-field {
  .mat-mdc-form-field-flex {
    width: 240px;
  }
  .mat-mdc-form-field-flex {
    height: 44px;
    align-items: center;
  }
  .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
    height: fit-content;
    margin-top: 0;
    padding: 12px 0;
    border: none;
  }
  .mat-mdc-form-field-wrapper {
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
  .mat-mdc-form-field-label {
    font-size: 1.2rem;
    padding-top: 12px;
  }

  input {
    margin-top: 6px;
  }
}

//　名刺詳細の名刺メモ上書き
.visit-card-memo-textarea .mat-mdc-form-field {
  height: 100%;

  .mat-mdc-text-field-wrapper {
    height: 100%;
    .mat-mdc-form-field-flex {
      height: 100%;

      div {
        height: 100%;

        textarea {
          height: 100%;
          resize: none;
        }
      }
    }
  }
}

.mat-mdc-header-cell {
  z-index: 5 !important;
}

/** Angular Material SnackBar Style */
// TODO: 利用されているか不明
.snackbar-config {
  color: white;
  background-color: variables.$usonar-accent-color !important;
}

/** SnackBarのメッセージカラー */
.error {
  --mdc-snackbar-container-color: #ffcdd2 !important;
  --mdc-snackbar-supporting-text-color: #263238 !important;
}

.warning {
  --mdc-snackbar-container-color: #fff9c4 !important;
  --mdc-snackbar-supporting-text-color: #263238 !important;
}

.complete {
  --mdc-snackbar-container-color: #c8e6c9 !important;
  --mdc-snackbar-supporting-text-color: #263238 !important;
}
